import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const getTickets = (filters = {}) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const params = new URLSearchParams(filters);
    return axios
      .get(`${BASE_URL}${BASE_VERSION}admin-v1/ticket/all?${params.toString()}`, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return { error };
      });
  };
};

export const getTicketDetail = (id) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "admin-v1/ticket/" + id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return { error };
      });
  };
};

export const createTicket = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const data = new FormData();

    if (payload.image) {
      data.append("files", payload.image);
    }

    Object.keys(payload).forEach(key => {
      if (key !== 'image') {
        data.append(key, payload[key]);
      }
    });

    return axios
      .post(BASE_URL + BASE_VERSION + "admin-v1/ticket", data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return { error };
      });
  };
};

export const updateTicket = (id, payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const data = new FormData();

    if (payload.image) {
      data.append("files", payload.image);
    }

    Object.keys(payload).forEach(key => {
      if (key !== 'image') {
        data.append(key, payload[key]);
      }
    });

    return axios
      .put(BASE_URL + BASE_VERSION + "admin-v1/ticket/" + id, data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return { error };
      });
  };
};

export const deleteTicket = (id) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .delete(BASE_URL + BASE_VERSION + "admin-v1/ticket/" + id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return { error };
      });
  };
};

export const getEventList = () => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(`${BASE_URL}${BASE_VERSION}event/admin/all`, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return { error };
      });
  };
};
