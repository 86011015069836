import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTickets, getEventList } from "../../../redux/actions/tickets";
import { useHistory, Link, useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { CalendarIcon, CurrencyDollarIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

function Tickets() {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [filters, setFilters] = useState({
    event: "",
    is_active: "true",
    order: "last",
  });
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    fetchTickets();
    fetchEvents();
  }, [filters]);

  const fetchTickets = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(getTickets(filters));
      if (res.code === 200) {
        setLoaded(true);
        setData(res.data);
      } else {
        alert("Something went wrong");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEvents = async () => {
    try {
      const res = await dispatch(getEventList());
      if (res.code === 200) {
        setEvents(res.data);
      } else {
        console.error("Failed to fetch events");
      }
    } catch (err) {
      console.error("Error fetching events:", err);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilters = () => {
    setFilters({
      event: "",
      is_active: "",
      order: "last",
    });
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="panel home-panel">
      <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-4">
        <span className="text-lg font-semibold">Tickets</span>
        <Link to={`${location.pathname}/add`}>
          <div className="bg-blue-700 hover:bg-blue-600 transition-colors duration-200 cursor-pointer p-2 text-sm rounded-md select-none">
            Add Ticket
          </div>
        </Link>
      </div>

      <div className="bg-white p-4 rounded-md shadow-md mb-6">
        <h2 className="text-lg font-semibold mb-3">Filter Tickets</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="event" className="block text-sm font-medium text-gray-700 mb-1">
              Event
            </label>
            <select
              id="event"
              name="event"
              value={filters.event}
              onChange={handleFilterChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">All Events</option>
              {events.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.title}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="is_active" className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              id="is_active"
              name="is_active"
              value={filters.is_active}
              onChange={handleFilterChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">All Statuses</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
          <div>
            <label htmlFor="order" className="block text-sm font-medium text-gray-700 mb-1">
              Sort By
            </label>
            <select
              id="order"
              name="order"
              value={filters.order}
              onChange={handleFilterChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="last">Newest First</option>
              <option value="old">Oldest First</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-4">
        <button
          onClick={resetFilters}
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-md transition-colors duration-200"
        >
          Reset Filters
        </button>
      </div>

      {isLoading ? (
        <div className="text-center py-4">Loading...</div>
      ) : data.length > 0 ? (
        <div className="ticket-list grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {data.map((ticket) => (
            <div
              key={ticket.id}
              className="container bg-zinc-50 p-4 rounded-md shadow flex flex-col gap-3 relative hover:shadow-lg transition-shadow duration-200"
            >
              <img
                src={ticket.imageurl}
                alt={`thumbnail-${ticket.name}`}
                className="ticket-item-thumbnail w-full h-40 object-cover rounded-md"
              />
              <div className="text-lg font-semibold truncate">{ticket.name}</div>
              <div className="flex items-center text-sm text-gray-600">
                <CalendarIcon className="h-4 w-4 mr-2" />
                <span>
                  {format(new Date(ticket.event_start_date), "dd MMM yyyy")} - 
                  {format(new Date(ticket.event_end_date), "dd MMM yyyy")}
                </span>
              </div>
              <div className="flex items-center text-sm">
                <CurrencyDollarIcon className="h-4 w-4 mr-2 text-gray-600" />
                <span className="font-semibold">Rp{ticket.price.toLocaleString()}</span>
              </div>
              <div className="flex items-center text-sm">
                {ticket.is_disabled ? (
                  <XCircleIcon className="h-4 w-4 mr-2 text-red-600" />
                ) : (
                  <CheckCircleIcon className="h-4 w-4 mr-2 text-green-600" />
                )}
                <span className={`font-semibold ${ticket.is_disabled ? 'text-red-600' : 'text-green-600'}`}>
                  {ticket.is_disabled ? "Disable" : "Enable"}
                </span>
              </div>
              <div className="flex items-center text-sm">
                {ticket.is_active ? (
                  <CheckCircleIcon className="h-4 w-4 mr-2 text-green-600" />
                ) : (
                  <XCircleIcon className="h-4 w-4 mr-2 text-red-600" />
                )}
                <span className={`font-semibold ${ticket.is_active ? 'text-green-600' : 'text-red-600'}`}>
                  {ticket.is_active ? "Active" : "Inactive"}
                </span>
              </div>
              <Link 
                to={`${location.pathname}/edit/${ticket.id}`} 
                className="absolute top-2 right-2 bg-orange-600 hover:bg-orange-700 text-white text-sm font-semibold py-1 px-3 rounded transition-colors duration-200"
              >
                Edit
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-4 text-gray-500">No tickets found</div>
      )}
    </div>
  );
}

export default Tickets;
