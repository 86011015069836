import React, { Component } from "react";
import { connect } from "react-redux";
import { updateTicket, getTicketDetail, getEventList } from "../../../redux/actions/tickets";
import { Switch, TextField, FormControlLabel, MenuItem } from "@mui/material";
import Files from "react-files";
import { withRouter } from "react-router-dom";

class TicketEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: {
        id: "",
        idevent: "",
        name: "",
        slug: "",
        caption: "",
        description: "",
        event_start_date: "",
        event_end_date: "",
        price: 0,
        is_sale: false,
        sale_price: 0,
        is_stock: false,
        stock: 0,
        min_ticket: 1,
        max_ticket: 1,
        sale_start_date: "",
        sale_end_date: "",
        sort_order: 0,
        is_disabled: false,
        is_active: true,
      },
      image: null,
      imageUrl: null,
      events: [],
      err: {},
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFileChange = (files) => {
    this.setState({
      image: files[0],
      imageUrl: files[0].preview.url,
    });
  };

  handleFileError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  handleChange(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props
      .updateTicket(this.state.data.id, {
        ...this.state.data,
        image: this.state.image,
      })
      .then((res) => {
        if (res.code === 200) {
          alert("Update Success!");
          this.props.history.push("/dashboard/tickets");
        } else {
          alert("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    const ticketId = this.props.match.params.id;
    Promise.all([
      this.props.getTicketDetail(ticketId),
      this.props.getEventList()
    ]).then(([ticketRes, eventsRes]) => {
      if (ticketRes.code === 200 && eventsRes.code === 200) {
        const ticketData = ticketRes.data;
        ticketData.event_start_date = this.formatDate(ticketData.event_start_date);
        ticketData.event_end_date = this.formatDate(ticketData.event_end_date);
        ticketData.sale_start_date = this.formatDate(ticketData.sale_start_date);
        ticketData.sale_end_date = this.formatDate(ticketData.sale_end_date);
        this.setState({
          data: ticketData,
          imageUrl: ticketData.imageurl,
          events: eventsRes.data,
          loaded: true,
        });
      } else {
        alert("Something Error");
      }
    });
  }

  formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16);
  }

  render() {
    if (!this.state.loaded) {
      return <div>Loading...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Edit Ticket</span>
        </div>
        <form className="w-full flex flex-col gap-3 pt-2" onSubmit={this.handleSubmit}>
          <div className="w-1/2">
            <Files
              className="files-dropzone"
              onChange={this.handleFileChange}
              onError={this.handleFileError}
              accepts={["image/*"]}
              multiple={false}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <img
                src={this.state.imageUrl || this.state.data.imageurl}
                alt={"thumbnail-" + this.state.data.name}
                className="cursor-pointer ticket-item-thumbnail w-full hover:grayscale-0"
              />
              <span className="cursor-pointer text-center block w-full p-2">
                Drop files here or click to upload
              </span>
            </Files>
          </div>

          <TextField
            required
            select
            id="idevent"
            name="idevent"
            label="Event"
            value={this.state.data.idevent}
            onChange={this.handleChange}
          >
            {this.state.events.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                {event.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            id="name"
            name="name"
            type="text"
            label="Name"
            value={this.state.data.name}
            onChange={this.handleChange}
          />
          <TextField
            id="slug"
            name="slug"
            type="text"
            label="Slug"
            value={this.state.data.slug}
            onChange={this.handleChange}
          />
          <TextField
            id="caption"
            name="caption"
            type="text"
            label="Caption"
            value={this.state.data.caption}
            onChange={this.handleChange}
          />
          <TextField
            id="description"
            name="description"
            type="text"
            label="Description"
            multiline
            rows={4}
            value={this.state.data.description}
            onChange={this.handleChange}
          />
          <TextField
            required
            id="event_start_date"
            name="event_start_date"
            type="datetime-local"
            label="Event Start Date"
            InputLabelProps={{ shrink: true }}
            value={this.state.data.event_start_date}
            onChange={this.handleChange}
          />
          <TextField
            required
            id="event_end_date"
            name="event_end_date"
            type="datetime-local"
            label="Event End Date"
            InputLabelProps={{ shrink: true }}
            value={this.state.data.event_end_date}
            onChange={this.handleChange}
          />
          <TextField
            required
            id="price"
            name="price"
            type="number"
            label="Price"
            value={this.state.data.price}
            onChange={this.handleChange}
          />
          <FormControlLabel
            control={<Switch checked={this.state.data.is_sale} onChange={this.handleChange} name="is_sale" />}
            label="Is Sale"
          />
          <TextField
            id="sale_price"
            name="sale_price"
            type="number"
            label="Sale Price"
            value={this.state.data.sale_price}
            onChange={this.handleChange}
          />
          <FormControlLabel
            control={<Switch checked={this.state.data.is_stock} onChange={this.handleChange} name="is_stock" />}
            label="Is Stock"
          />
          <TextField
            id="stock"
            name="stock"
            type="number"
            label="Stock"
            value={this.state.data.stock}
            onChange={this.handleChange}
          />
          <TextField
            id="min_ticket"
            name="min_ticket"
            type="number"
            label="Min Ticket"
            value={this.state.data.min_ticket}
            onChange={this.handleChange}
          />
          <TextField
            id="max_ticket"
            name="max_ticket"
            type="number"
            label="Max Ticket"
            value={this.state.data.max_ticket}
            onChange={this.handleChange}
          />
          <TextField
            id="sale_start_date"
            name="sale_start_date"
            type="datetime-local"
            label="Sale Start Date"
            InputLabelProps={{ shrink: true }}
            value={this.state.data.sale_start_date}
            onChange={this.handleChange}
          />
          <TextField
            id="sale_end_date"
            name="sale_end_date"
            type="datetime-local"
            label="Sale End Date"
            InputLabelProps={{ shrink: true }}
            value={this.state.data.sale_end_date}
            onChange={this.handleChange}
          />
          <TextField
            id="sort_order"
            name="sort_order"
            type="number"
            label="Sort Order"
            value={this.state.data.sort_order}
            onChange={this.handleChange}
          />
          <FormControlLabel
            control={<Switch checked={this.state.data.is_disabled} onChange={this.handleChange} name="is_disabled" />}
            label="Is Disabled"
          />
          <FormControlLabel
            control={<Switch checked={this.state.data.is_active} onChange={this.handleChange} name="is_active" />}
            label="Is Active"
          />
          <div className="flex-0">
            <input
              type="submit"
              className="text-sm w-fit p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center"
              value="Update Ticket"
            />
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateTicket: (id, data) => dispatch(updateTicket(id, data)),
    getTicketDetail: (id) => dispatch(getTicketDetail(id)),
    getEventList: () => dispatch(getEventList()),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(TicketEdit));
